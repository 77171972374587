.mainWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.videoSection {
  flex: 1;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  margin: 0 auto;
}
.videoSection iframe {
  width: 100%;
  height: 300px;
  border: none;
  border-radius: 8px;
}

.buttonTextImage {
  background-color: #428c9f;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  border-radius: 15px;
  width: 325px;
}

.icon {
  margin-right: 10px;
  color: white;
  font-size: 24px;
}

.contactLink {
  display: inline-flex;
  align-items: center;
  color: #428c9f;
  text-decoration: none;
  font-size: 24px;
  gap: 8px;
}
.contactLink:hover {
  text-decoration: underline;
}

.formContainer {
  width: 530px;
  margin: 0 auto;
  flex: 1;
  position: relative;
}

.progressBar {
  width: 80%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.progress {
  height: 100%;
  background-color: #428c9f;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.stepMarkers {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: -20px;
  width: 100%;
  padding: 0;
}

.step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.step:first-child {
  transform: translateX(-10%);
}

.step:last-child {
  transform: translateX(10%);
}

.active {
  background-color: #428c9f;
  color: white;
}

.title {
  text-align: center;
}

.titlesFade {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.titlesFade ul {
  list-style-type: none;
  padding: 0;
}

.titlesFade ul li {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease, transform 0.3s ease;
}

.subtitle {
  text-align: center;
  margin-bottom: 20px;
  font-weight: normal;
}

.subtitleWhatsapp {
  text-align: center;
  font-weight: normal;
}

.link {
  color: #428c9f;
  text-decoration: underline;
}

.inputWrapper {
  position: relative;
}

.inputWrapper .input {
  width: 100%;
  padding-right: 40px;
}

.eyeButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.eyeButton:focus {
  outline: none;
}

.eyeButton svg {
  color: #888;
  font-size: 1.2em;
}

.input {
  width: 100%;
}

select {
  appearance: none;
  -webkit-appearance: none;
  background-color: white;
  padding: 0.5rem;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
}

select:focus {
  outline: none;
}

.card {
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cardHeader {
  background-color: white;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.cardTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: #428c9f;
  margin: 0;
}

.cardContent {
  padding: 1.5rem;
}

.inputGroup {
  margin-bottom: 1rem;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #428c9f;
  border-radius: 8px;
  font-size: 1rem;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f9fafb;
  border-top: 1px solid #e5e7eb;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.buttonPrimary {
  background-color: #428c9f;
  color: white;
  border: none;
}

.buttonPrimary:hover {
  background-color: #e5e7eb;
}

.buttonSecondary {
  background-color: white;
  color: #4b5563;
  border: 1px solid #d1d5db;
}

.buttonSecondary:hover {
  background-color: #f3f4f6;
}

.formGroup {
  margin-bottom: 20px;
  width: 100%;
}

.termsGroup label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
  display: block;
}

.termsGroup input[type="checkbox"] {
  width: auto;
}

.termsGroup input {
  width: 100%;
  padding: 12px;
  border: 1px solid #428c9f;
  border-radius: 8px;
  font-size: 1.1em;
  color: #333;
}

.termsGroup input:focus {
  outline: none;
  border-color: #2a6f80;
  box-shadow: 0 0 5px rgba(66, 140, 159, 0.5);
}

.termsLink {
  color: #428c9f;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s;
}

.termsLink:hover {
  color: #357384;
}

@media (max-width: 1024px) {
  .mainWrapper {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .videoSection {
    max-width: 100%;
    height: auto;
    padding: 20px;
  }

  .videoSection iframe {
    height: 250px;
  }

  .title {
    font-size: 1.8rem;
  }

  .formContainer {
    width: 60%;
    margin: 0 auto;
  }

  .progressBar {
    width: 90%;
    margin: 30px auto;
  }

  .step {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 1rem;
  }

  .cardContent {
    padding: 1rem;
  }

  .cardTitle {
    font-size: 1.1rem;
    margin-bottom: 30px;
  }

  .input {
    font-size: 0.95rem;
  }

  .button {
    padding: 0.4rem 1rem;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .progressBar {
    width: 95%;
    margin: 20px auto;
  }

  .stepMarkers {
    align-items: center;
    top: -12px;
  }

  .step:first-child,
  .step:last-child {
    transform: translateX(0);
  }

  .step {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .title {
    font-size: 1.5rem;
  }

  .formContainer {
    width: 80%;
    margin: 0 auto;
  }

  .row {
    flex-direction: column;
    display: flex;
    gap: 10px;
  }

  .dropdown {
    margin-right: 0;
  }

  .cardContent {
    padding: 1rem;
  }

  .cardFooter {
    flex-direction: column;
    gap: 10px;
  }

  .button {
    width: 100%;
  }

  .termsLink {
    flex-basis: 100%;
  }
}

@media (max-width: 480px) {
  .progressBar {
    width: 100%;
    margin: 15px auto;
  }

  .formContainer {
    width: 80%;
    margin: 0 auto;
  }
  .step {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 0.9rem;
  }

  .inputWrapper .input {
    padding-right: 30px;
  }

  .eyeButton {
    right: 5px;
  }

  .cardTitle {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .input {
    padding: 0.4rem;
    font-size: 0.9rem;
  }

  .button {
    padding: 0.3rem 0.8rem;
    font-size: 0.9rem;
  }
}

.freeBadge {
  position: absolute;
  top: 90px;
  right: -40px;
  width: 95px;
  background-color: #428c9f;
  color: white;
  padding: 8px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-out;
  z-index: 10;
  transform: rotate(15deg);
}

.badgeIcon {
  font-size: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
